<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-15 21:06:58
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '商品展示',
          list: [
            {
              name: '商品分类',
              path: '/mall/commodity/product-category'
            },
            {
              name: '商品列表',
              path: '/mall/commodity/product'
            }
          ]
        }
      ]
    };
  }
};
</script>
